import React, { Fragment } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import PropTypes from 'prop-types';
import Overline from '../Typography/Overline';
import { minimalRoundedCorners } from '../RoundedCorners';
import gradients from '../Theme/gradients';
import colors from '../Theme/colors';

const CategoryLabelsWrapper = styled.span`
  display: inline-block;
  padding-right: 8px;
`;

const Pipe = styled.span`
  padding: 0 4px;
  position: relative;
  bottom: 1px;
`;

const newsItemLabel = css`
  ${minimalRoundedCorners};
  color: var(--white);
  display: inline-block;
  margin: 2px 8px 0 0;
  font-size: 0.5rem;
  text-align: center;
  position: relative;
  top: -1px;
`;

export const FeaturedLabel = styled(Overline)`
  ${newsItemLabel};
  padding: 1px 2px 0px 3px;
  background-color: ${
  (properties) => properties.theme.palette?.primaryFallback.main
};
  background-color: ${
  (properties) => properties.theme.palette?.primary.main
};
  background-image: linear-gradient(to right, ${gradients.subtle});
`;

export const MemberAccessLabel = styled(Overline)`
  ${newsItemLabel};
  padding: 1px 3px 0px;
  background-color: ${colors.black};
  background-image: linear-gradient(to right, ${gradients.executive});
`;

const CardOverlineSuffix = styled.span`
  color: var(--black-inactive);
  display: inline-block;
  padding-left: 0.3rem;
`;

const Labels = ({
  category,
  labels,
  isPressRelease,
  isPartnerContent,
}) => (
  <CategoryLabelsWrapper>
    { category }
    { labels && labels.length > 0
      && (
        <>
          <Pipe>|</Pipe>
          {labels.map((label) => (
            <Fragment key={label}>
              {label}
              {' '}
            </Fragment>
          ))}
        </>
      )}
    { isPressRelease && (
      <CardOverlineSuffix>Press Release</CardOverlineSuffix>
    )}
    { isPartnerContent && (
      <CardOverlineSuffix>Partner Content</CardOverlineSuffix>
    )}
  </CategoryLabelsWrapper>
);

Labels.propTypes = {
  category: PropTypes.string,
  labels: PropTypes.arrayOf(PropTypes.string),
  isPressRelease: PropTypes.bool,
  isPartnerContent: PropTypes.bool,
};

Labels.defaultProps = {
  category: undefined,
  labels: [],
  isPressRelease: false,
  isPartnerContent: false,
};

export default Labels;
