import rose from '../Palettes/rose';
import colors from './colors';

const gradients = {
  DEFAULT: `${rose[400]}, ${colors.primary}`,
  subtle: `${rose[500]}, ${colors.primary}`,
  executive: `${colors.black}, ${colors.blackGradient}`,
};

export default gradients;
